import React from "react";

function Projects() {
  const projects = [
    {
      name: "Proyecto 1",
      description: "Descripción del proyecto 1",
      url: "https://proyecto1.com",
    },
    {
      name: "Proyecto 2",
      description: "Descripción del proyecto 2",
      url: "https://proyecto2.com",
    },
    // Puedes añadir más proyectos aquí.
  ];

  return (
    <section className="projects" id="projects">
      <h2>Mis Proyectos</h2>
      <div className="project-list">
        {projects.map((project, index) => (
          <div key={index} className="project">
            <h3>{project.name}</h3>
            <p>{project.description}</p>
            <a href={project.url} target="_blank" rel="noopener noreferrer">
              Ver Proyecto
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
