import React from "react";

function About() {
  return (
    <section className="about" id="about">
      <h2>Sobre Mí</h2>
      <p>
        Soy un desarrollador apasionado con experiencia en JavaScript, React, Node.js, 
        y otras tecnologías modernas. Me gusta crear soluciones eficientes y escalables.
      </p>
    </section>
  );
}

export default About;
