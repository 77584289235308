import React from "react";

function Contact() {
  return (
    <section className="contact" id="contact">
      <h2>Contacto</h2>
      <p>
        Si estás interesado en trabajar conmigo o conocer más de lo que hago, no dudes en contactarme.
      </p>
      <a href="mailto:tucorreo@dominio.com" className="btn">Envíame un correo</a>
    </section>
  );
}

export default Contact;
