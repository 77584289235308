import React from "react";
import { motion } from "framer-motion"; // Importar animación de Framer Motion

function Header() {
  return (
    <header className="header">
      <motion.div 
        className="header-content"
        initial={{ opacity: 0 }} // Comienza invisible
        animate={{ opacity: 1 }} // Se anima a ser visible
        transition={{ duration: 1 }} // Duración de la animación
      >
        <h1>¡Hola! Soy [Quique]</h1>
        <p>Desarrollador Web Frontend</p>
        <a href="#projects" className="btn">Ver mis proyectos</a>
      </motion.div>
    </header>
  );
}

export default Header;
